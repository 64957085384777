var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"value":_vm.dialog,"width":"440","content-class":"v-dialog__form"},on:{"update:value":function($event){_vm.dialog=$event}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-content"},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v("Export types by custom view")])]),_c('v-icon',{attrs:{"color":"#fff"},on:{"click":_vm.cancel}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"items-wrapper"},[_c('div',{staticClass:"items"},[_c('div',{staticClass:"item-wrapper"},[_c('v-autocomplete',{staticClass:"follow-products-autocomplete",attrs:{"attach":"","item-text":"name","item-value":"id","allow-overflow":false,"menu-props":{
                maxHeight: '220px',
              },"no-data-text":"No custom views available","items":_vm.customViews,"placeholder":"Choose custom view for exporting"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"small":""}},[_vm._v(" mdi-tag-outline ")]),_vm._v(" "+_vm._s(item.filterTag)+" ")],1)]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"small":""}},[_vm._v(" mdi-tag-outline ")]),_vm._v(" "+_vm._s(item.filterTag)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])]),_c('v-card-actions',{staticClass:"pt-6 d-flex justify-end"},[[_c('v-btn',{attrs:{"outlined":"","color":"lightBlue"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"lightBlue","disabled":!_vm.selected},on:{"click":_vm.exportSchedule}},[_vm._v(" Export ")])]],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }